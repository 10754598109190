import React from 'react';

import T from 'i18n-react';

import styled from 'styled-components';

import { AppRoute } from '../../../../routes/routes';
import { BackLink } from '../../../../components/BackLink';
import { Header } from '../../../../components/Header';
import { Content } from '../../../../components/Content';
import { ArrowSvg } from '../../../../assets/react-svg/ArrowSvg';
import { Share } from '../../../../components/Share';
import {
  SUCCESS,
  TEXT_BLACK,
  TEXT,
  BACKGROUND
} from '../../../../styles/colors';

export const RequestContactSuccess: React.FC = () => {
  return (
    <>
      <Header bgColor={SUCCESS} textColor={TEXT_BLACK}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackLink>
        <p>{T.translate('ResultsUnknown.title')}</p>
      </Header>

      <Content>
        <Title>{T.translate('ResultsUnknown.success.title')}</Title>
        <P>{T.translate('ResultsUnknown.success.text')}</P>

        <Share bgColor={BACKGROUND} textColor={SUCCESS} svgColor={SUCCESS} />
      </Content>
    </>
  );
};

const P = styled.section`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${SUCCESS};
  margin: 0 0 2.4rem;
`;
