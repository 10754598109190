import React from 'react';

interface EmailSvgProps {
  color: string;
}

export const EmailSvg: React.FC<EmailSvgProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <filter id="57q20k4hba">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.003922 0 0 0 0 0.003922 0 0 0 0 0.003922 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g
              filter="url(#57q20k4hba)"
              transform="translate(-167 -386) translate(143 260) translate(0 109)"
            >
              <g>
                <path d="M0 0H16V16H0z" transform="translate(24 17)" />
                <path
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M2.683 2.9H13.35c.733 0 1.333.6 1.333 1.333v8c0 .734-.6 1.334-1.333 1.334H2.683c-.733 0-1.333-.6-1.333-1.334v-8c0-.733.6-1.333 1.333-1.333z"
                  transform="translate(24 17)"
                />
                <path
                  stroke="#222"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M14.683 4.233L8.017 8.9 1.35 4.233"
                  transform="translate(24 17)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
