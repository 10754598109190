import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import T from 'i18n-react';

import { AppRoute } from '../../routes/routes';
import { getIdUrlAnalysis, getUrlAnalysis } from '../../services/urlChecker';
import { UrlChecker } from '../../model/urlChecker';

import { Layout } from '../../components/Layout';
import { ResultsSafe } from './ResultsSafe';
import { ResultsUnknown } from './ResultsUnknown';
import { ResultsUnsafe } from './ResultsUnsafe';
import { ResultsLoading } from './ResultsLoading';
import { ErrorView } from '../ErrorView';
import { ResultClassification } from './ResultClassification';

export const ResultsView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [urlToVerify, setUrlToVerify] = useState('');
  const [urlAnalysis, setUrlAnalysis] = useState(UrlChecker.LOADING);
  const [idAnalysis, setIdAnalysis] = useState('');

  const getUrlFromSearch = () => {
    const { search } = location;
    try {
      const url = new URLSearchParams(search).get('url');
      return decodeURIComponent(url || '');
    } catch {
      return null;
    }
  };

  useEffect(() => {
    const url = getUrlFromSearch();

    if (!url) {
      history.push(AppRoute.Home);
    } else {
      setUrlToVerify(url);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!!urlToVerify) {
        const idUrlAnalysis = await getIdUrlAnalysis(urlToVerify);

        const idIsValid =
          idUrlAnalysis !== UrlChecker.ERROR_PENDING_ANALYSIS &&
          idUrlAnalysis !== UrlChecker.ERROR_URL_NOT_FOUND &&
          idUrlAnalysis !== UrlChecker.ERROR_UNKNOWN;

        if (idIsValid) {
          setIdAnalysis(idUrlAnalysis);

          let analysisWithValidation = await getUrlAnalysis(idUrlAnalysis);
          if (!analysisWithValidation) {
            analysisWithValidation = UrlChecker.ERROR_PENDING_ANALYSIS;
          }

          setUrlAnalysis(analysisWithValidation);
        } else {
          setUrlAnalysis(idUrlAnalysis);
        }
      }
    })();
  }, [urlToVerify]);

  return (
    <Layout>
      {
        {
          [UrlChecker.LOADING]: <ResultsLoading url={urlToVerify} />,

          [UrlChecker.SAFE]: <ResultsSafe url={urlToVerify} />,

          [UrlChecker.SAFE_NEWS]: (
            <ResultClassification
              url={urlToVerify}
              selectedClassification={UrlChecker.SAFE_NEWS}
            />
          ),
          [UrlChecker.SAFE_SOCIAL_MEDIA]: (
            <ResultClassification
              url={urlToVerify}
              selectedClassification={UrlChecker.SAFE_SOCIAL_MEDIA}
            />
          ),
          [UrlChecker.SAFE_E_COMMERCE]: (
            <ResultClassification
              url={urlToVerify}
              selectedClassification={UrlChecker.SAFE_E_COMMERCE}
            />
          ),

          [UrlChecker.UNSAFE]: <ResultsUnsafe url={urlToVerify} />,
          [UrlChecker.UNKNOWN]: (
            <ResultsUnknown url={urlToVerify} idAnalysis={idAnalysis} />
          ),
          [UrlChecker.ERROR_PENDING_ANALYSIS]: (
            <ErrorView text={T.translate('error.pendingAnalysis') as string} />
          ),
          [UrlChecker.ERROR_URL_NOT_FOUND]: (
            <ErrorView text={T.translate('error.urlNotFound') as string} />
          ),
          [UrlChecker.ERROR_UNKNOWN]: (
            <ErrorView text={T.translate('error.unknownSearch') as string} />
          )
        }[urlAnalysis]
      }
    </Layout>
  );
};
