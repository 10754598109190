import React from 'react';

interface ArrowSvgProps {
  color?: string;
  svgName?: string;
}

export const ArrowSvg: React.FC<ArrowSvgProps> = ({ color, svgName }) => {

  const rotate = (svgName === 'back') ? '180' : '90';

  return (
    <svg width="17px" height="16px" viewBox="0 0 17 16" version="1.1">
      <title>icons/arrow</title>
      <defs>
        <filter id="filter-1">
          <feColorMatrix in="SourceGraphic" type="matrix"></feColorMatrix>
        </filter>
      </defs>
      <g id="Site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Home-Desktop" transform="translate(-147.000000, -355.000000)">
          <g
            id="icons/arrow"
            transform="translate(143.000000, 351.000000)"
            filter="url(#filter-1)"
          >
            <g transform={`translate(12.000000, 12.000000) rotate(${rotate}.000000) translate(-12.000000, -12.000000)`}>
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g
                id="arrow-right"
                transform="translate(4.500000, 4.500000)"
                // stroke="#5ee19e"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path
                  d="M0.75,7 L13.75,7 M7.75,0 L14.75,7 L7.75,14"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
