import React from 'react';

import T from 'i18n-react';

import styled from 'styled-components';

import { AppRoute } from '../../../routes/routes';
import { Header } from '../../../components/Header';
import { BackLink } from '../../../components/BackLink';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';

import {
  TEXT,
  DANGER,
  DANGER_TWO,
  BACKGROUND_TWO
} from '../../../styles/colors';
import { Share } from '../../../components/Share';

interface ResultsUnsafeProps {
  url: string;
}

export const ResultsUnsafe: React.FC<ResultsUnsafeProps> = ({ url }) => {
  return (
    <>
      <Header bgColor={DANGER} textColor={TEXT}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT} />
        </BackLink>
        <p>{url}</p>
      </Header>

      <Content>
        <Container>
          <Title>{T.translate('ResultsUnsafe.title')}</Title>
          <p>{T.translate('ResultsUnsafe.text')}</p>

          <Share bgColor={DANGER} textColor={TEXT} svgColor={TEXT} />
        </Container>
      </Content>

      <ContentTwo>
        <Row>
          <div>
            <h2>{T.translate('ResultsUnsafe.col_1.title')}</h2>
            <p>{T.translate('ResultsUnsafe.col_1.text_1')}</p>
            <p>{T.translate('ResultsUnsafe.col_1.text_2')}</p>
          </div>

          <div>
            <h2>{T.translate('ResultsUnsafe.col_2.title')}</h2>
            <p>{T.translate('ResultsUnsafe.col_2.text')}</p>
          </div>
        </Row>
      </ContentTwo>
    </>
  );
};

const Content = styled.section`
  padding: 4.8rem 3.2rem 4.8rem;
  background-color: ${DANGER_TWO};

  p {
    max-width: 615px;
    color: ${TEXT};
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
  }

  @media (min-width: 600px) {
    padding: 8rem 4.8rem 64px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 1080px;
  }
`;

const ContentTwo = styled.section`
  padding: 4.8rem 3.2rem 97px;
  background-color: ${BACKGROUND_TWO};
  flex: 1;

  h2,
  p {
    color: ${TEXT};
    font-weight: normal;
  }

  h2 {
    font-size: 2.4rem;
    margin: 0 0 2.4rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
  }

  @media (min-width: 600px) {
    padding: 8rem 4.8rem 64px;
  }
`;

const Row = styled.div`
  margin: 0 auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  div {
    :first-child {
      margin-bottom: 48px;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 100%;
      max-width: 448px;
      :first-child {
        margin-right: 4.8rem;
      }
    }

    max-width: 1080px;
  }
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${TEXT};
  margin: 0 0 2.4rem;
`;
