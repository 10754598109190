import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import T from 'i18n-react';
import { useHistory } from 'react-router-dom';
import { SearchSvg } from '../../../assets/react-svg/SearchSvg';
import { BACKGROUND_TWO, TEXT, TEXT_TWO } from '../../../styles/colors';
import { AppRoute } from '../../../routes/routes';
import { urlValidator } from '../../../validators/urlValidator';

export const ActionsWrapper: React.FC = () => {
  const history = useHistory();
  const [url, setUrl] = useState('');
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const onKeySubmitHandler = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const ENTER_KEY = 'Enter';

    if (event.key === ENTER_KEY) {
      event.preventDefault();
      redirectToResults();
    }
  };

  const changeTextHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value.replace(/[\n\r\t\v\s]/g, '');
    setUrl(newText);
  };

  const onClickSvgHandler = () => {
    redirectToResults();
  };

  const redirectToResults = () => {
    const isValidUrl = urlValidator(url);
    setIsInvalidUrl(false);
    if (isValidUrl) {
      const urlToVerify = encodeURIComponent(url);
      history.push(`${AppRoute.Results}?url=${urlToVerify}`);
    } else {
      setIsInvalidUrl(true);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Textarea
          data-testid="actions-textarea"
          autoFocus
          onChange={changeTextHandler}
          onKeyPress={onKeySubmitHandler}
          placeholder={String(T.translate('home.placeholder'))}
          value={url}
          rows={1}
        />
        <SearchSvg onClickHandler={onClickSvgHandler} />
      </Wrapper>
      {isInvalidUrl && <small className="invalid-url">URL inválida</small>}
    </Container>
  );
};

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Container = styled.section`
  background-color: ${BACKGROUND_TWO};
  color: white;
  flex: 1;
  font-size: 35px;
  padding: 3.4rem 0;

  .invalid-url {
    max-width: 1080px;
    width: 90%;
    display: block;
    margin: 0.6rem auto 0;
    color: ${TEXT};
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media (max-height: 640px) {
    padding: 3.2rem 0;
  }

  @media (max-width: 640px) {
    .invalid-url {
      width: 82%;
    }
  }
`;
Container.displayName = 'ActionsWrapper';

const Wrapper = styled.div`
  line-height: 0;
  margin: 0 auto;
  max-width: 1080px;
  padding-right: 29px;
  position: relative;
  width: 90%;
  animation: ${fade} 0.25s linear;

  svg {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 640px) {
    width: 82%;
  }
`;

const Textarea = styled.textarea`
  background-color: ${BACKGROUND_TWO};
  border: none;
  word-break: break-all;
  color: ${TEXT};
  cursor: auto;
  font-size: 1.6rem;
  height: 100%;
  outline: none;
  resize: none;
  width: 100%;

  overflow: hidden scroll;
  scrollbar-width: thin;
  scrollbar-color: ${TEXT_TWO} ${BACKGROUND_TWO};

  ::-webkit-scrollbar {
    width: 0.6rem;
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.35px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.35rem;
    background: ${TEXT_TWO};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${TEXT_TWO};
  }
  ::-webkit-scrollbar-thumb:active {
    background: ${TEXT_TWO};
  }
`;
