import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { AppRoute } from '../../../routes/routes';

import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { BackLink } from '../../../components/BackLink';
import { Header } from '../../../components/Header';
import { Layout } from '../../../components/Layout';

import {
  BACKGROUND_TWO,
  TEXT,
  TEXT_BLACK,
  WARNING
} from '../../../styles/colors';
import { Content } from '../../../components/Content';

export const ResultsReport = () => {
  return (
    <Layout>
      <Header bgColor={WARNING} textColor={TEXT_BLACK}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackLink>
        <p>Denunciar site</p>
      </Header>

      <Content>
        <Form>
          <Input
            autoFocus={true}
            placeholder=" Cole aqui o endereço (URL) do site que deseja denunciar..."
            type="email"
          />

          <Button to={AppRoute.ReportSuccess}>Enviar</Button>
        </Form>
      </Content>
    </Layout>
  );
};

const Form = styled.form`
  display: block;
`;

const Input = styled.input`
  border: none;
  word-break: break-all;
  color: ${TEXT};
  cursor: auto;
  font-size: 1.6rem;
  height: 100%;
  outline: none;
  resize: none;
  width: 100%;
  background-color: ${BACKGROUND_TWO};
  margin-bottom: 160px;
  display: block;
`;

const Button = styled(Link)`
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 30px;
  margin-bottom: 1.6rem;
  padding: 15px 24px 18px;
  font-size: 1.6rem;
  color: ${TEXT_BLACK};
  background-color: ${WARNING};
  transition: opacity 0.25s;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  :hover {
    opacity: 0.9;
  }
`;
