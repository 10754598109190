import React from 'react';

interface ExternalLinkSvgProps {
  color?: string;
}

export const ExternalLinkSvg: React.FC<ExternalLinkSvgProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <filter id="dfb3gkeyqa">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.369444 0 0 0 0 0.881652 0 0 0 0 0.620819 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g
              // filter="url(#dfb3gkeyqa)"
              transform="translate(-332 -386) translate(143 260) translate(0 124)"
            >
              <g>
                <path d="M0 0H16V16H0z" transform="translate(189 2)" />
                <path
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M12 9.333v3.334c0 .736-.597 1.333-1.333 1.333H3.333C2.597 14 2 13.403 2 12.667V5.333C2 4.6 2.6 4 3.333 4h3.334M10 2h4v4M6.667 9.333l6.8-6.8"
                  transform="translate(189 2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
