export enum UrlChecker {
  LOADING = 'LOADING',
  SAFE = 'SAFE',
  SAFE_NEWS = 'news',
  SAFE_SOCIAL_MEDIA = 'social-media',
  SAFE_E_COMMERCE = 'e-commerce',
  UNSAFE = 'UNSAFE',
  UNKNOWN = 'UNKNOWN',
  ERROR_PENDING_ANALYSIS = 'ERROR_PENDING_ANALYSIS',
  ERROR_URL_NOT_FOUND = 'ERROR_URL_NOT_FOUND',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN'
}
