import React from 'react';
import { Link } from 'react-router-dom';

import T from 'i18n-react';

import { BackLink } from '../../components/BackLink';
import { Content } from '../../components/Content';
import { Header } from '../../components/Header';
import { AppRoute } from '../../routes/routes';
import { ArrowSvg } from '../../assets/react-svg/ArrowSvg';
import { BACKGROUND, DANGER_TWO, TEXT } from '../../styles/colors';

import styled from 'styled-components';

interface ResultsErrorProps {
  text: string;
  shouldReloadOnLinkClick?: boolean;
  linkBack?: string;
}

export const ErrorView: React.FC<ResultsErrorProps> = ({
  text,
  shouldReloadOnLinkClick,
  linkBack = AppRoute.Home
}) => {
  const handleShouldReloadOnLinkClick = (event: React.FormEvent) => {
    if (shouldReloadOnLinkClick) {
      event.preventDefault();
      window.location.reload();
    }
  };

  return (
    <>
      <Header bgColor={BACKGROUND} textColor={TEXT}>
        <BackLink to={linkBack} onClick={handleShouldReloadOnLinkClick}>
          <ArrowSvg svgName="back" color={TEXT} />
        </BackLink>
      </Header>

      <Content>
        <div>
          <Title>Ops!</Title>

          {/* id para tratamento no mixpanel */}
          <P id="error-message">{text}</P>
        </div>

        <Button to={linkBack} onClick={handleShouldReloadOnLinkClick}>
          {T.translate('buttons.back')}
        </Button>
      </Content>
    </>
  );
};

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${DANGER_TWO};
  margin: 0 0 2.4rem;
`;

const P = styled.section`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
`;

const Button = styled(Link)`
  display: inline-block;
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 30px;
  padding: 15px 24px 18px;
  font-size: 1.6rem;
  color: ${TEXT};
  text-decoration: none;
  background-color: ${BACKGROUND};
  outline: none;
  transition: opacity 0.25s;

  position: absolute;
  bottom: 0;

  :hover {
    opacity: 0.9;
  }
`;
