import React from 'react';
import { AppRoute } from '../../../routes/routes';

import styled from 'styled-components';

import { Header } from '../../../components/Header';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { BackLink } from '../../../components/BackLink';

import {
  BACKGROUND,
  SUCCESS,
  TEXT,
  TEXT_BLACK,
  WARNING
} from '../../../styles/colors';
import { Content } from '../../../components/Content';
import { Share } from '../../../components/Share';

const Classification = {
  News: 'news',
  SocialMedia: 'social-media',
  ECommerce: 'e-commerce'
};

interface ResultClassificationProps {
  selectedClassification: string;
  url: string;
}

export const ResultClassification: React.FC<ResultClassificationProps> = ({
  selectedClassification,
  url
}) => {
  const accentColor =
    selectedClassification === Classification.ECommerce ? SUCCESS : WARNING;
  return (
    <>
      <Header bgColor={accentColor} textColor={TEXT_BLACK}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackLink>
        <p>{url}</p>
      </Header>

      <Content>
        {
          {
            [Classification.News]: (
              <div>
                <Title>Este parece ser um site de notícias verdadeiro.</Title>
                <P>
                  No entanto, o Posso Confiar não verifica a veracidade das
                  notícias publicadas. Você pode verificar se as notícias são
                  verdadeiras em sites como o da{' '}
                  <ExternalLink
                    href="https://piaui.folha.uol.com.br/lupa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Agência Lupa
                  </ExternalLink>
                  .
                </P>
              </div>
            ),
            [Classification.SocialMedia]: (
              <div>
                <Title>
                  Este link parece vir de uma rede social verdadeira.
                </Title>
                <P>
                  No entanto, o Posso Confiar não verifica a reputação de perfis
                  ou páginas específicas nas redes sociais. Evite fornecer
                  informações pessoais a perfis desconhecidos nas redes.
                </P>
              </div>
            ),
            [Classification.ECommerce]: (
              <div>
                <Title>
                  Esta página não está se passando por outra página ou marca,
                  portanto, não se trata de uma página falsa.
                </Title>
                <P>
                  Entretanto, o Posso Confiar não averigua a qualidade dos
                  produtos adquiridos no site. Nesse caso, você pode averiguar a
                  reputação da loja em sites como o{' '}
                  <ExternalLink
                    href="https://www.reclameaqui.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color={accentColor}
                  >
                    Reclame Aqui
                  </ExternalLink>
                  .
                </P>
              </div>
            )
          }[selectedClassification]
        }

        <Share
          bgColor={BACKGROUND}
          textColor={accentColor}
          svgColor={accentColor}
        />
      </Content>
    </>
  );
};

const Title = styled.h1`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${TEXT};
  margin: 0 0 2.4rem;
  max-width: 616px;
`;

const P = styled.p`
  color: #9c9a94;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
  max-width: 616px;
  margin-bottom: 80px;
`;

const ExternalLink = styled.a`
  color: ${props => props.color};
  text-decoration: none;
  transition: opacity 0.25s;

  :hover {
    opacity: 0.9;
  }
`;
