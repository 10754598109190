import React from 'react';

import { AppRoute } from '../../../routes/routes';
import { BackLink } from '../../../components/BackLink';
import { Header } from '../../../components/Header';
import { Content } from '../../../components/Content';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';

import { BACKGROUND, TEXT } from '../../../styles/colors';

import styled, { keyframes } from 'styled-components';

interface ResultsLoadingProps {
  url: string;
}
export const ResultsLoading: React.FC<ResultsLoadingProps> = ({ url }) => (
  <>
    <Header bgColor={BACKGROUND} textColor={TEXT}>
      <BackLink to={AppRoute.Home}>
        <ArrowSvg svgName="back" color={TEXT} />
      </BackLink>
      <p>{url}</p>
    </Header>

    <Content>
      <LoadingWrapper>
        Verificando
        <LoadingAnimation>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </LoadingAnimation>
      </LoadingWrapper>
    </Content>
  </>
);

const LoadingWrapper = styled.p`
  font-size: 2.8rem;
  color: ${TEXT};
  opacity: 0.6;
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;    
  }
`;

const LoadingAnimation = styled.span`
  span {
    opacity: 0;
    animation: ${fade} 1s infinite;

    :nth-child(1) {
      animation-delay: 0.33s;
    }
    :nth-child(2) {
      animation-delay: 0.66s;
    }
    :nth-child(3) {
      animation-delay: 0.99s;
    }
  }

`;
