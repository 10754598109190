import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppRoute } from './routes';
import { HomeView } from '../views/HomeView';
import { ResultsView } from '../views/ResultsView';
import { AboutView } from '../views/AboutView';
import { NotFoundView } from '../views/NotFoundView';
import { ResultsReport, ResultsReportSuccess } from '../views/ResultsView/ResultsReport';

export const AppRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route path={AppRoute.Home} exact component={HomeView} />
      <Route path={AppRoute.Results} exact component={ResultsView} />
      <Route path={AppRoute.About} exact component={AboutView} />
      <Route path={AppRoute.Report} exact component={ResultsReport} />
      <Route path={AppRoute.ReportSuccess} exact component={ResultsReportSuccess} />
      <Route component={NotFoundView} />
    </Switch>
  </BrowserRouter>
);
