import React, { useState } from 'react';

import T from 'i18n-react';

import styled, { keyframes } from 'styled-components';

import { ArrowSvg } from '../../../../assets/react-svg/ArrowSvg';
import { EmailSvg } from '../../../../assets/react-svg/EmailSvg';
import { Content } from '../../../../components/Content';
import { Header } from '../../../../components/Header';
import { Layout } from '../../../../components/Layout';
import { PageState } from '../ResultsUnknown';
import { requestManualAnalysis } from '../../../../services/requestContact';
import {
  BACKGROUND_TWO,
  TEXT,
  TEXT_BLACK,
  WARNING,
  DANGER_TWO
} from '../../../../styles/colors';

interface RequestContactProps {
  idAnalysis: string;
  setPageState: (state: any) => void;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;

export const RequestContact: React.FC<RequestContactProps> = ({
  idAnalysis,
  setPageState
}) => {
  const [email, setEmail] = useState('');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [isEmailFeedbackVisible, setIsEmailFeedbackVisible] = useState(false);
  const [isTermsFeedbackVisible, setIsTermsFeedbackVisible] = useState(false);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (agreedTerms) {
      setIsTermsFeedbackVisible(false);
    } else {
      setIsTermsFeedbackVisible(true);
    }

    const isEmailValid = !!email.trim() && EMAIL_REGEX.test(email);
    if (isEmailValid) {
      setIsEmailFeedbackVisible(false);
    } else {
      setIsEmailFeedbackVisible(true);
    }

    if (isEmailValid && agreedTerms) {
      const sentRequestContact = await requestManualAnalysis(
        idAnalysis,
        email,
        agreedTerms
      );

      if (sentRequestContact) {
        setPageState(PageState.REQUEST_CONTACT_SUCCESS);
      } else {
        setPageState(PageState.ERROR);
      }
    }
  };

  return (
    <Layout>
      <Header bgColor={WARNING} textColor={TEXT_BLACK}>
        <BackButton onClick={() => setPageState(PageState.ANALYSIS_RESULT)}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackButton>
        <p>{T.translate('ResultsUnknown.title')}</p>
      </Header>

      <Content>
        <form onSubmit={handleFormSubmit}>
          <Input
            autoFocus={true}
            placeholder={`${T.translate('ResultsUnknown.placeholder')}`}
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          {isEmailFeedbackVisible && (
            <EmailFeedback>
              {T.translate('ResultsUnknown.EmailFeedback')}
            </EmailFeedback>
          )}

          <Terms>
            <input
              id="terms"
              type="checkbox"
              checked={agreedTerms}
              onChange={event => setAgreedTerms(event.target.checked)}
            />
            <span></span>
            <label htmlFor="terms">
              {T.translate('ResultsUnknown.terms')}{' '}
              <a
                href="https://axur.com/pt/politica-de-privacidade/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {T.translate('ResultsUnknown.label')}
              </a>
            </label>
          </Terms>
          {isTermsFeedbackVisible && (
            <TermsFeedback>
              {T.translate('ResultsUnknown.TermsFeedback')}
            </TermsFeedback>
          )}
          <Button type="submit">
            <EmailSvg color={TEXT_BLACK} />
            <span>{T.translate('buttons.report')}</span>
          </Button>
        </form>

        <Small>{T.translate('buttons.reportLabel')}</Small>
      </Content>
    </Layout>
  );
};

const fade = keyframes`
  from {
    opacity: 0;
    
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(-4);
    transform: translateX(-4);
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  margin-bottom: 1.6rem;

  @media (min-width: 600px) {
    margin-bottom: 2.5rem;
  }
`;

const Button = styled.button`
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 30px;
  margin-bottom: 1.6rem;
  padding: 15px 24px 18px;
  font-size: 1.6rem;
  color: ${TEXT_BLACK};
  background-color: ${WARNING};
  transition: opacity 0.25s;

  display: flex;
  align-items: center;

  span {
    margin-left: 12px;
  }

  :hover {
    opacity: 0.9;
  }
`;

const Small = styled.p`
  max-width: 246px;
  color: ${TEXT};
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-bottom: 4.8rem;
  opacity: 0.6;
`;

const Input = styled.input`
  border: none;
  word-break: break-all;
  color: ${TEXT};
  cursor: auto;
  font-size: 1.6rem;
  height: 100%;
  outline: none;
  resize: none;
  width: 100%;
  background-color: ${BACKGROUND_TWO};
  /* opacity: 0.6; */
`;

const Terms = styled.div`
  margin: 6.4rem 0 2.4rem;
  color: ${TEXT};
  font-size: 1.3rem;

  display: flex;
  align-items: center;

  position: relative;

  input[type='checkbox'] {
    /* display:none; */
    border: none;
    width: 21px;
    height: 21px;
    z-index: 1;
    /* background-color: transparent; */
    appearance: none;
  }

  input[type='checkbox'] + span {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 0;
    border-radius: 4px;
    border: 1px solid ${TEXT};
    background-color: ${BACKGROUND_TWO};
    font-size: 0;
  }

  input:checked + span {
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z'/%3E%3Cpath stroke='%23010101' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M13.667 4l-7.334 7.333L3 8'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-color: ${TEXT};
  }

  label {
    margin-left: 1.6rem;
    cursor: pointer;
  }

  a {
    color: ${WARNING};
    text-decoration: none;
    transition: opacity 0.25s;

    :hover {
      opacity: 0.9;
    }
  }
`;

const EmailFeedback = styled.p`
  color: ${DANGER_TWO};
  font-size: 13px;
  margin-top: 1.6rem;
  animation: ${fade} 0.25s linear;
`;

const TermsFeedback = styled.p`
  color: ${DANGER_TWO};
  font-size: 13px;
  margin-bottom: 2.4rem;
  animation: ${fade} 0.25s linear;
`;
