import React, { useState } from 'react';
import T from 'i18n-react';
import styled from 'styled-components';

import { AppRoute } from '../../../routes/routes';
import { BackLink } from '../../../components/BackLink';
import { Header } from '../../../components/Header';
import { Content } from '../../../components/Content';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { ExternalLinkSvg } from '../../../assets/react-svg/ExternalLinkSvg';
import { Share } from '../../../components/Share';

import { SUCCESS, TEXT_BLACK, TEXT, BACKGROUND } from '../../../styles/colors';

interface ResultsSafeProps {
  url: string;
}

export const ResultsSafe: React.FC<ResultsSafeProps> = ({ url }) => {
  const [urlWithProtocol, setUrlWithProtocol] = useState(url);

  const hasProtocol =
    urlWithProtocol.includes('http://') || urlWithProtocol.includes('https://');

  if (!hasProtocol) {
    setUrlWithProtocol(`https://${urlWithProtocol}`);
  }

  return (
    <>
      <Header bgColor={SUCCESS} textColor={TEXT_BLACK}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackLink>
        <p>{url}</p>
      </Header>

      <Content>
        <Title>Pode confiar!</Title>
        <P>O site é legítimo. Parabéns por ser cauteloso e verificar!</P>
        <ExternalLink
          href={urlWithProtocol}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Acesse o site verificado</span>
          <ExternalLinkSvg color={SUCCESS} />
        </ExternalLink>
        <Share
          bgColor={BACKGROUND}
          textColor={SUCCESS}
          svgColor={SUCCESS}
          title={T.translate('results.share.safe', { url }) as string}
        />
      </Content>
    </>
  );
};

const P = styled.section`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${SUCCESS};
  margin: 0 0 2.4rem;
`;

const ExternalLink = styled.a`
  display: block;
  color: ${SUCCESS};
  font-size: 1.6rem;
  text-decoration: none;
  margin-bottom: 8rem;
  display: inline-flex;
  align-items: center;
  transition: opacity 0.25s;

  span {
    margin-right: 8px;
  }

  :hover {
    opacity: 0.9;
  }
`;
