import React, { useState } from 'react';
import T from 'i18n-react';
import { ErrorView } from '../../ErrorView';
import { AnalysisResult } from './resultsUnknown/AnalysisResult';
import { RequestContact } from './resultsUnknown/RequestContact';
import { RequestContactSuccess } from './resultsUnknown/RequestContactSuccess';
import { AppRoute } from '../../../routes/routes';

interface ResultsUnknownProps {
  url: string;
  idAnalysis: string;
}

export enum PageState {
  ANALYSIS_RESULT = 'ANALYSIS_RESULT',
  REQUEST_CONTACT = 'REQUEST_CONTACT',
  REQUEST_CONTACT_SUCCESS = 'REQUEST_CONTACT_SUCCESS',
  ERROR = 'ERROR'
}

export const ResultsUnknown: React.FC<ResultsUnknownProps> = ({
  url,
  idAnalysis
}) => {
  const [pageState, setPageState] = useState(PageState.ANALYSIS_RESULT);
  return (
    <>
      {
        {
          [PageState.ANALYSIS_RESULT]: (
            <AnalysisResult url={url} setPageState={setPageState} />
          ),
          [PageState.REQUEST_CONTACT]: (
            <RequestContact
              idAnalysis={idAnalysis}
              setPageState={setPageState}
            />
          ),
          [PageState.REQUEST_CONTACT_SUCCESS]: <RequestContactSuccess />,
          [PageState.ERROR]: (
            <ErrorView
              text={T.translate('error.unknownRequestContact') as string}
              linkBack={`${AppRoute.Results}?url=${encodeURIComponent(url)}`}
              shouldReloadOnLinkClick={true}
            />
          )
        }[pageState]
      }
    </>
  );
};
