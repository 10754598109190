import React from 'react';

import { AppRoute } from '../../../routes/routes';

import styled from 'styled-components';

import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { BackLink } from '../../../components/BackLink';
import { Header } from '../../../components/Header';
import { Layout } from '../../../components/Layout';

import { SUCCESS, TEXT, TEXT_BLACK } from '../../../styles/colors';
import { Content } from '../../../components/Content';
import { Share } from '../../../components/Share';

export const ResultsReportSuccess = () => {
  return (
    <Layout>
      <Header bgColor={SUCCESS} textColor={TEXT_BLACK}>
        <BackLink to={AppRoute.Report}>
          <ArrowSvg svgName="back" color={TEXT_BLACK} />
        </BackLink>
        <p>Denunciar site</p>
      </Header>

      <Content>
        <Title>Obrigado</Title>
        <P>
          Sua denúncia ajuda a proteger muitas pessoas de golpes e fraudes
          digitais.
        </P>

        <Share bgColor={SUCCESS} textColor={TEXT_BLACK} svgColor={TEXT_BLACK} />
      </Content>
    </Layout>
  );
};

const P = styled.section`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
  margin-bottom: 112px;
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${SUCCESS};
  margin: 0 0 2.4rem;
`;
