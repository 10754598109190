export const BACKGROUND = '#010101';
export const BACKGROUND_TWO = '#1e1e1e';
export const SUCCESS = '#5ee19e';
export const WARNING = '#f9d43a';
export const DANGER = '#d66340';
export const DANGER_TWO = '#eb6d46';

export const TEXT = '#f0ece2';
export const TEXT_TWO = '#5ee19e';
export const TEXT_THREE = '#666666';
export const TEXT_BLACK = '#010101';
