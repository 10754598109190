import React from 'react';

import T from 'i18n-react';

import styled from 'styled-components';

import { AppRoute } from '../../../../routes/routes';
import { Header } from '../../../../components/Header';

import { BackLink } from '../../../../components/BackLink';
import { Content } from '../../../../components/Content';
import { ArrowSvg } from '../../../../assets/react-svg/ArrowSvg';
// import { EmailSvg } from '../../../../assets/react-svg/EmailSvg';
// import { ShareSvg } from '../../../../assets/react-svg/ShareSvg';
import {
  TEXT_BLACK,
  TEXT,
  WARNING
  // BACKGROUND
} from '../../../../styles/colors';
// import { PageState } from '../ResultsUnknown';
// import { Share } from '../../../../components/Share';

interface ResultsUnknownProps {
  url: string;
  setPageState: (state: any) => void;
}

export const AnalysisResult: React.FC<ResultsUnknownProps> = (
  // {url, setPageState}
  { url }
) => (
  <>
    <Header bgColor={WARNING} textColor={TEXT_BLACK}>
      <BackLink to={AppRoute.Home}>
        <ArrowSvg svgName="back" color={TEXT_BLACK} />
      </BackLink>
      <p>{url}</p>
    </Header>

    <Content>
      <Title>{T.translate('ResultsUnknown.text_1')}</Title>
      <P>{T.translate('ResultsUnknown.text_2')}</P>

      {/* <Button onClick={() => setPageState(PageState.REQUEST_CONTACT)}>
          <EmailSvg color={TEXT_BLACK} />
          <span>{T.translate('buttons.report')}</span>
        </Button> */}

      {/* <Small>{T.translate('buttons.reportLabel')}</Small> */}

      {/* <Share bgColor={BACKGROUND} textColor={WARNING} svgColor={WARNING} /> */}
    </Content>
  </>
);

// const Button = styled.button`
//   cursor: pointer;
//   appearance: none;
//   border: none;
//   border-radius: 30px;
//   margin-bottom: 1.6rem;
//   padding: 15px 24px 18px;
//   font-size: 1.6rem;
//   color: ${TEXT_BLACK};
//   background-color: ${WARNING};
//   transition: opacity 0.25s;

//   display: flex;
//   align-items: center;

//   span {
//     margin-left: 12px;
//   }

//   :hover {
//     opacity: 0.9;
//   }
// `;

const Title = styled.h1`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${TEXT};
  margin: 0 0 2.4rem;
`;

// const Small = styled.p`
//   max-width: 246px;
//   color: ${TEXT};
//   font-size: 1.3rem;
//   line-height: 1.8rem;
//   margin-bottom: 4.8rem;
//   opacity: 0.6;
// `;

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
  opacity: 0.6;
`;
