import axios from 'axios';
import { MANUAL_ANALYSIS } from '../routes/gatewayRoutes';

export const requestManualAnalysis = async (
  id: string,
  email: string,
  agreedTerms: boolean
) => {
  return axios
    .put(MANUAL_ANALYSIS(id), { email, agreedTerms })
    .then(() => true)
    .catch(() => false);
};
