import React from 'react';
import styled, { keyframes } from 'styled-components';
import T from 'i18n-react';
import { Link } from 'react-router-dom';
import {
  BACKGROUND,
  SUCCESS,
  TEXT,
  TEXT_THREE,
  TEXT_TWO
} from '../../../styles/colors';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { AppRoute } from '../../../routes/routes';

export const ContentWrapper: React.FC = () => (
  <Container>
    <Wrapper>
      <Header>
        <ul>
          <li>
            <StyledLink to={AppRoute.About}>
              {T.translate('home.header.about')}
            </StyledLink>
          </li>
          <li>
            <ExternalLink
              href="https://axur.com/pt/politica-de-privacidade/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {T.translate('home.header.policies')}
            </ExternalLink>
          </li>
        </ul>
      </Header>

      <Beta>Beta</Beta>

      <Title
        dangerouslySetInnerHTML={{
          __html: T.translate('home.title') as string
        }}
      />
      <ArrowSvg color={SUCCESS} />
    </Wrapper>
  </Container>
);

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Container = styled.section`
  background-color: ${BACKGROUND};
  color: white;
  font-size: 35px;
  height: 55%;
  min-height: 300px;
  padding: 6rem 0 3.2rem 0;

  @media (max-height: 500px) {
    height: 95%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  width: 90%;
  animation: ${fade} 0.25s linear;

  @media (max-width: 640px) {
    width: 82%;
  }
`;

const Header = styled.header`
  position: absolute;
  top: 0;

  a,
  a:hover,
  a:focus {
    font-size: 1.3rem;
    text-decoration: none;
  }

  li {
    display: inline-block;

    & + li {
      margin-left: 2.4rem;
    }
  }
`;

const StyledLink = styled(Link)`
  &,
  &:hover,
  &:focus {
    color: ${TEXT_TWO};
  }
`;

const ExternalLink = styled.a`
  &,
  &:hover,
  &:focus {
    color: ${TEXT_THREE};
  }
`;

const Beta = styled.p`
  max-width: 46px;
  color: ${TEXT};
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.67px;
  border: 1px solid ${TEXT};
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 3px 8px;
  opacity: 0.6;
`;

const Title = styled.p`
  font-size: 3.6rem;
  color: ${TEXT};
  margin-bottom: 2.4rem;
`;

Container.displayName = 'ContentWrapper';
