import { createGlobalStyle } from 'styled-components';
import { BACKGROUND } from '../colors';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  html {
    font-size: 10px;
  }
  body, #app {
    height: 100vh;
    /* background-color: ${BACKGROUND}; */
  }
  p {
    margin: 0;
    color: #fff;
  }
  input {
    padding: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  button {
    outline: none;
  }
`;
