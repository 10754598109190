import React from 'react';
import T from 'i18n-react';
import styled from 'styled-components';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

import { ShareSvg } from '../../assets/react-svg/ShareSvg';
import { TEXT } from '../../styles/colors';

interface ShareProps {
  bgColor: string;
  textColor: string;
  svgColor?: string;
  title?: string;
}

export const Share: React.FC<ShareProps> = ({
  bgColor,
  textColor,
  svgColor,
  title = undefined
}) => {
  const url = 'https://possoconfiar.com.br';

  return (
    <>
      <P>{T.translate('buttons.share.text')}</P>
      <ShareButtonWrapper>
        <Button bgColor={bgColor} textColor={textColor}>
          <ShareSvg color={svgColor} />
          <span>{T.translate('buttons.share.label')}</span>
          <ShareButtons>
            <FacebookShareButton url={url}>
              <li>
                <FacebookIcon size={36} round={true} />
              </li>
            </FacebookShareButton>

            <LinkedinShareButton url={url}>
              <li>
                <LinkedinIcon size={36} round={true} />
              </li>
            </LinkedinShareButton>

            <TwitterShareButton url={url}>
              <li>
                <TwitterIcon size={36} round={true} />
              </li>
            </TwitterShareButton>

            <WhatsappShareButton url={url} title={title}>
              <li>
                <WhatsappIcon size={36} round={true} />
              </li>
            </WhatsappShareButton>
          </ShareButtons>
        </Button>
      </ShareButtonWrapper>
    </>
  );
};

interface ShareWrapperProps {
  bgColor: string;
  textColor: string;
}

const ShareButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div<ShareWrapperProps>`
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 30px;
  padding: 15px 24px 18px;
  font-size: 1.6rem;
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  transition: opacity 0.25s;
  position: relative;

  display: flex;
  align-items: center;

  margin-right: 8px;

  span {
    margin-left: 12px;
  }

  nav {
    padding-left: 10px;
    opacity: 0;
    transition: opacity 0.25s;
  }

  :hover {
    opacity: 0.9;
    nav {
      opacity: 1;
    }
  }
`;

const ShareButtons = styled.nav`
  position: absolute;
  right: -170px;

  li {
    margin-right: 4px;
    list-style: none;
    transition: opacity 0.25s;

    :hover {
      opacity: 0.9;
    }
  }

  @media (max-width: 600px) {
    right: 10px;
    top: 64px;
    width: 170px;
  }
`;

const P = styled.section`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
  max-width: 320px;

  @media (max-width: 640px) {
    min-width: 100%;
  }
`;
