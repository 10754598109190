import axios from 'axios';
import { UrlChecker } from '../model/urlChecker';
import { getAxiosInstanceWithDelay } from '../requests/axiosInstanceWithDelay';
import { URL_CHECKER_CREATE, URL_CHECKER_READ } from '../routes/gatewayRoutes';

const AXIOS_RETRIES = 5;
const DEFAULT_ATTEMPT_COUNTER = 1;
const INITIAL_MILLISECONDS_COOLDOWN = 2000;
const GENERAL_MILLISECONDS_COOLDOWN = 3000;

export const getIdUrlAnalysis = async (url: string) => {
  return axios
    .post(URL_CHECKER_CREATE, { url })
    .then(response => response.data.id)
    .catch(error => getErrorResult(error.toString()));
};

export const getUrlAnalysis = (
  id: string,
  attemptCounter: number = DEFAULT_ATTEMPT_COUNTER
): any => {
  const isInitialAttemptCounter = attemptCounter === 1 || !attemptCounter;
  const millisecondsCooldown = isInitialAttemptCounter
    ? INITIAL_MILLISECONDS_COOLDOWN
    : GENERAL_MILLISECONDS_COOLDOWN;

  const axiosInstanceWithDelay = getAxiosInstanceWithDelay(
    millisecondsCooldown
  );

  return axiosInstanceWithDelay
    .get(URL_CHECKER_READ(id))
    .then(response => {
      const shouldRetry =
        response.status === 204 && attemptCounter <= AXIOS_RETRIES;

      if (shouldRetry) {
        const newAttemptCounter = attemptCounter + 1;
        return getUrlAnalysis(id, newAttemptCounter);
      } else {
        // APENAS TESTE EM DEV
        // response.data = { result: 'SAFE', classification: 'news' };
        // response.data = { result: 'SAFE', classification: 'social-media' };
        // response.data = { result: 'SAFE', classification: 'e-commerce' };

        let analysys = response.data.result;

        const shoulCheckClassification =
          response.data.result === UrlChecker.SAFE ||
          response.data.result === UrlChecker.UNKNOWN;

        if (shoulCheckClassification) {
          if (!!response.data.classification) {
            if (
              response.data.classification === UrlChecker.SAFE_NEWS ||
              response.data.classification === UrlChecker.SAFE_SOCIAL_MEDIA ||
              response.data.classification === UrlChecker.SAFE_E_COMMERCE
            )
              analysys = response.data.classification.toLowerCase();
          }
        }
        return response.status === 200
          ? analysys
          : UrlChecker.ERROR_PENDING_ANALYSIS;
      }
    })
    .catch(error => getErrorResult(error.toString()));
};

interface ErrorMapping {
  [error: string]: UrlChecker;
}

const getErrorResult = (err: string): string => {
  const errorWithMessage = ['400'];
  const errorMapping: ErrorMapping = {
    400: UrlChecker.ERROR_URL_NOT_FOUND
  };

  const errorCode = err.match(/\d{3}/);

  if (!errorCode) {
    return UrlChecker.ERROR_UNKNOWN;
  }

  const error = errorCode[0];

  if (errorWithMessage.includes(error)) {
    return errorMapping[error] || UrlChecker.ERROR_UNKNOWN;
  } else {
    return UrlChecker.ERROR_UNKNOWN;
  }
};
