/* eslint-disable no-useless-escape */
export const urlValidator = (url: string): boolean => {
  const urlRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  if (url && urlRegex.test(url.toLocaleLowerCase())) {
    return true;
  }

  return false;
};
